import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import SapAccoladesSlider from "../components/sliders/sap-accolades-slider/sap-accolades-slider"
import { StaticImage } from "gatsby-plugin-image"
import TestimonialSlider from "../components/sliders/sap-testimonial-slider/sap-testimonial-slider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import ClientsSlider from "../components/sliders/clients-slider/clients-slider"
import BackgroundImage from "gatsby-background-image"

const SAP = ({ data }) => {
  const clientImages = {
    Access: getImage(data.Access),
    acorn: getImage(data.acorn),
    advantis: getImage(data.advantis),
    bondville: getImage(data.bondville),
    court: getImage(data.court),
    cwm: getImage(data.cwm),
    dimoc: getImage(data.dimoc),
    ebcc: getImage(data.ebcc),
    ewm: getImage(data.ewm),
    als: getImage(data.als),
    ferentino: getImage(data.ferentino),
    hemasc: getImage(data.hemasc),
    iwmi: getImage(data.iwmi),
    jkh: getImage(data.jkh),
    lanwa: getImage(data.lanwa),
    laugfs: getImage(data.laugfs),
    lion: getImage(data.lion),
    loadstar: getImage(data.loadstar),
    melwa: getImage(data.melwa),
    printcare: getImage(data.printcare),
    rocklandc: getImage(data.rocklandc),
    ssl: getImage(data.ssl),
    teejay: getImage(data.teejay),
    tfg: getImage(data.tfg),
    um: getImage(data.um),
    walkers: getImage(data.walkers),
    wkvc: getImage(data.wkvc),
    atg: getImage(data.atg),
    rocklandnew: getImage(data.rocklandnew),
    adani: getImage(data.adani),
  }

  const accoladesImages = {
    y2014: getImage(data.y2014),
    y2015: getImage(data.y2015),
    y2016: getImage(data.y2016),
    y2018: getImage(data.y2018),
    y2020: getImage(data.y2020),
    y2021: getImage(data.y2021),
    y2022: getImage(data.y2022),
    cwml: getImage(data.cwml),
    dimo: getImage(data.dimo),
    ebc: getImage(data.ebc),
    hemas: getImage(data.hemas),
    lanka_ssl: getImage(data.lanka_ssl),
    rockland: getImage(data.rockland),
    wkv: getImage(data.wkv),
    sapacc: getImage(data.sapacc),
  }

  const testimonialsImages = {
    mangala: getImage(data.mangala),
    gerard: getImage(data.gerard),
    lalith: getImage(data.lalith),
  }

  const images = {
    sapBg: convertToBgImage(getImage(data.sapBg)),
    Asset3: convertToBgImage(getImage(data.Asset3)),
    Sap_Products: convertToBgImage(getImage(data.Sap_Products)),
    SuccsessStories: convertToBgImage(getImage(data.SuccsessStories)),
  }

  const clients_slider = [
    {
      index: 0,
      image: clientImages.Access,
    },
    {
      index: 1,
      image: clientImages.acorn,
    },
    {
      index: 2,
      image: clientImages.advantis,
    },

    {
      index: 3,
      image: clientImages.atg,
    },

    {
      index: 4,
      image: clientImages.bondville,
    },
    {
      index: 5,
      image: clientImages.court,
    },
    {
      index: 6,
      image: clientImages.cwm,
    },
    {
      index: 7,
      image: clientImages.dimoc,
    },
    {
      index: 8,
      image: clientImages.ebcc,
    },
    {
      index: 9,
      image: clientImages.ewm,
    },
    {
      index: 10,
      image: clientImages.als,
    },
    {
      index: 11,
      image: clientImages.ferentino,
    },
    {
      index: 12,
      image: clientImages.hemasc,
    },
    {
      index: 13,
      image: clientImages.iwmi,
    },
    {
      index: 14,
      image: clientImages.lanwa,
    },
    {
      index: 15,
      image: clientImages.laugfs,
    },
    {
      index: 16,
      image: clientImages.lion,
    },
    {
      index: 17,
      image: clientImages.loadstar,
    },
    {
      index: 18,
      image: clientImages.melwa,
    },
    {
      index: 19,
      image: clientImages.printcare,
    },

    {
      index: 20,
      image: clientImages.rocklandnew,
    },

    {
      index: 21,
      image: clientImages.ssl,
    },
    {
      index: 22,
      image: clientImages.teejay,
    },
    {
      index: 23,
      image: clientImages.tfg,
    },
    {
      index: 24,
      image: clientImages.um,
    },
    {
      index: 25,
      image: clientImages.walkers,
    },
    {
      index: 26,
      image: clientImages.wkvc,
    },
  ]

  const accolade_slider = [
    {
      index: 0,
      year: 2022,
      img1: accoladesImages.y2022,
      img2: accoladesImages.sapacc,
      line1:
        "Mid Market Award for Business ByDesign (Cloud ERP) Partner of the Year 2021 SAP Partner Success Summit ",
    },
    {
      index: 1,
      year: 2021,
      img1: accoladesImages.y2021,
      img2: accoladesImages.ebc,
      img3: accoladesImages.cwml,
      img4: accoladesImages.lanka_ssl,
      line1: "Sri Lanka's first S/4HANA 2020 Version Upgrade Customers",
    },

    {
      index: 2,
      year: 2021,
      img1: accoladesImages.y2021,
      img2: accoladesImages.dimo,
      line1:
        "SAP Best Mid Market Deal of the Year On-Premise (India, Sri Lanka and Bangladesh) for project DIMO",
    },
    {
      index: 3,
      year: 2020,
      img1: accoladesImages.y2020,
      img2: accoladesImages.dimo,
      title: "Topic goes here",
      line1: "Sri Lanka’s First SAP Qualtrics (Customer Experience) Customer",
      line2: "Sri Lanka’s First Proaxia (Automotive DBM) Customer",
      line3: "Sri Lanka’s First Vistex (Incentives and Promotions) Customer",
      line4: "Sri Lanka’s First SAP Field Service Management Customer",
    },
    {
      index: 4,
      year: 2018,
      img1: accoladesImages.y2018,
      img2: accoladesImages.wkv,
      line1: "Sri Lanka’s First SAP Business ByDesign (Cloud ERP) Customer",
    },
    {
      index: 5,
      year: 2018,
      img1: accoladesImages.y2018,
      img2: accoladesImages.sapacc,
      line1:
        "SAP Partner of the Year /Emerging Markets  (Sri Lanka & Bangladesh)",
    },
    {
      index: 6,
      year: 2016,
      img1: accoladesImages.y2016,
      img2: accoladesImages.rockland,
      line1: "Sri Lanka’s First SAP Hybris C4C (Cloud CRM) Customer",
    },
    {
      index: 7,
      year: 2016,
      img1: accoladesImages.y2016,
      img2: accoladesImages.ebc,
      img3: accoladesImages.cwml,
      img4: accoladesImages.lanka_ssl,
      line1: "Sri Lanka’s First S/4HANA ERP(1511) Customer",
    },
    {
      index: 8,
      year: 2015,
      img1: accoladesImages.y2015,
      img2: accoladesImages.hemas,
      line1: "Sri Lanka’s First SAP SuccessFactors (Cloud HCM) Customer",
    },

    {
      index: 9,
      year: 2015,
      img1: accoladesImages.y2015,
      img2: accoladesImages.sapacc,
      line1: "Sri Lankan Partner for the Highest SAP Licensing Revenue",
    },
  ]

  const testimoniallogo = {
    vertiv: getImage(data.vertiv),
    sap4hana: getImage(data.sap4hana),
    sapbussiness: getImage(data.sapbussiness),
  }

  const testimonial_slider = [
    {
      index: 0,
      image: testimonialsImages.mangala,
      name: "Mangala Wickramasinghe",
      logo: testimoniallogo.sap4hana,
      position: "Group Chief Information Officer",
      company: "Diesel & motor Engineering PLC",
      description:
        "As a Group, we transitioned into industries including Medical Equipment and Engineering while our existing systems and processes were primarily aligned to the Automotive industry. This was the main reason behind our need for a tier one ERP solution such as SAP, that could function across industries. We selected Tech Pacific after much consideration, and still remain grateful for the choice. They were outstanding in terms of understanding our requirements and supporting us in translating that into ERP solutions. Tech Pacific exceeded all expectations in visualising the digital transformation for the entire group",
    },
    {
      index: 1,
      image: testimonialsImages.gerard,
      name: "Gerard Suares",
      logo: testimoniallogo.sapbussiness,
      position: "Chief Financial Officer",
      company: "W.K.V. Hydro Technics (Pvt) Ltd",
      description:
        "Our association with Tech Pacific dates back to their inception in 2008. Seldom does one find a partner who brings a precise vision to life; and Tech pacific has been consistent in this regard. They exceeded our expectations by carrying out the company’s digital transformation with seamless expertise, professionalism and timely delivery. It has been a pleasant journey, and we strongly recommend Tech Pacific to any business embarking on the progressive journey of digital transformation.",
    },
    {
      index: 2,
      image: testimonialsImages.lalith,
      name: "Lalith Kulasinghe",
      logo: testimoniallogo.sap4hana,
      position: "Chief Information Officer",
      company: "Colombo Fort Group Service PVT LTD",
      description:
        "Tech Pacific carried out the implementation of SAP S/4HANA ERP system for two of our companies; namely E.B Creasy Group and C.W Mackie group, both subsidiaries of Colombo Fort Land and Building PLC. A significant change that enabled both companies to have a centralized system for business operations back in 2016. We are currently working with the same team on upgrading to the latest S/4HANA 2020, with embedded FIORI for the next-generation workforce. Having worked with Tech pacific for half a decade, we have witnessed their technical competencies and customer oriented business strategy first hand. This has helped establish a partnership that goes beyond business, and we have no hesitation in recommending Tech Pacific.",
    },
  ]

  return (
    <Layout>
      <Seo title="SAP | Tech Pacific Lanka" />
      <div>
        <section>
          <div className="main">
            <BackgroundImage {...images.sapBg} className="sap-header">
              <div className="container">
                <div className="sap-header-content">
                  <div className="img">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/sap/sap-header-logo.png"
                      width={300}
                      alt="techpac"
                    />
                  </div>
                  <div className="header-title">
                    No. 01 SAP Partner in Sri Lanka
                  </div>
                  <div className="header-description">
                    Tech Pacific offers a suite of SAP solutions, which together
                    with personalized service facilitates highly successful
                    Digital Transformation across diverse aspects of each
                    business, making it the preferred partner for businesses
                    looking to level up.
                  </div>
                  <div className="header-buttons d-flex">
                    <Link to="/contact">
                      <button>Contact</button>
                    </Link>
                    <a href="tel:1234567890">
                      <button>Call Now</button>
                    </a>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </section>
        <section>
          <div className="mid-typo container text-center">
            <div className="typo-title">Gold Partner since 2015</div>
            <div className="typo-description">
              SAP evaluates all of its partners on a stringent system of
              criteria including business performance, technological skill and
              innovation and customer base to grant gold status. Tech Pacific
              has continued to be recognized as a Gold Partner over the past
              seven years, having performed at the highest levels across the
              board while being aligned to SAP’s competency and strategy.
            </div>
          </div>
        </section>
        <section>
          <div className="accolades-main">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="sap-accolades-content">
                    <div className="sap-accolades-title">SAP Accolades</div>
                    <div className="sap-accolades-description">
                      Tech Pacific has consistently achieved excellence in
                      supporting our clients across SAP solutions with
                      dedication to teamwork, innovative approaches and
                      end-to-end solutions.
                    </div>
                    <Link to="/contact">
                      <button>Talk to us</button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 sap-slider-section ">
                  <div className="d-flex align-items-center slider-years ">
                    <SapAccoladesSlider slides={accolade_slider} />

                    <div className="swiper-button-next-white"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <BackgroundImage {...images.Asset3} className="sap-testimonials">
            <div className="container">
              <div className="sap-testimonials-title">What Clients Say</div>
              <div className="sap-testimonials-content d-flex">
                <div className="sap-testimonials-description">
                  Tech Pacific works closely with clients to understand their
                  problems, processes and requirements and provide them the
                  best-fit SAP solutions and thus have formed strong
                  partnerships with them over the years. Here is what their
                  clients - in their own words - have to say.
                </div>
                <div className="sap-button">
                  <Link to="/contact">
                    <button>Talk to us</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="swiper-button-prev1"></div>
              <div className="testimonial-super-main">
                <div className="testimonial-item-main">
                  <TestimonialSlider slides={testimonial_slider} />
                </div>
              </div>
              <div className="swiper-button-next1"></div>
            </div>
          </BackgroundImage>
        </section>
        <section>
          <BackgroundImage {...images.Sap_Products} className="mid-screen">
            <div className="mid-screen-content container">
              <span className="category">Digital Transformation</span>
              <span className="mid-screen-title">SAP Products</span>
              <span className="mid-screen-description">
                Tech Pacific offers a wide range of SAP solutions for Digital
                Transformation, targeting multiple facets of a business. An
                offering tailored through bespoke service, it integrates digital
                technology into all areas of a business, resulting in
                fundamental operational changes.{" "}
              </span>
              <Link to="/sap">
                <div className="learn-more">
                  LEARN MORE
                  <div className="icon">
                    <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                  </div>
                </div>
              </Link>
            </div>
          </BackgroundImage>
        </section>
        <section>
          <div className="sap-grid-main">
            <div className="grid-row d-flex">
              <div className="grid-item light" id="Sap4hana">
                <div className="grid-item-content">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/Sap4hana.png"
                      width={300}
                      alt="techpac"
                    />
                  </div>
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">SAP S/4HANA</span>
                    <span className="sap-grid-description">
                      SAP S/4HANA Cloud is a complete enterprise resource
                      planning (ERP) system with built-in intelligent
                      technologies, including AI, machine learning, and advanced
                      analytics. It helps companies adopt new business models,
                      manage business change at speed, orchestrate internal and
                      external resources, and use the predictive power of AI.
                      Benefit from tight, native integration between processes,
                      industry depth, and a consistent in-memory data model.
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href="https://www.sap.com/products/s4hana-erp.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="grid-item dark" id="riseWithSap">
                <div className="grid-item-content">
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">
                      Rise with SAP S/4HANA
                    </span>
                    <span className="sap-grid-description">
                      RISE with SAP offers a holistic on-your-terms and
                      on-your-timeline transformation to an intelligent
                      enterprise. You can remove complexity with a simplified
                      engagement and guided journey through your business
                      transformation. We offer you a faster time to premier
                      value and the flexibility you need without a high up-front
                      investment
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href="https://www.sap.com/mena/products/rise.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-row d-flex">
              <div className="grid-item dark" id="sapBusinessByDesign">
                <div className="grid-item-content">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/sap_bBD.png"
                      width={300}
                      alt="techpac"
                    />
                  </div>
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">
                      SAP Business ByDesign (ByD)
                    </span>
                    <span className="sap-grid-description">
                      SAP Business ByDesign delivers pre-built processes for
                      everything from finance and sales to product management
                      and purchasing on a single, unified solution. Connect
                      every function across your company with proven best
                      practices and in-depth analytics
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href="https://www.sap.com/products/business-bydesign.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="grid-item light" id="sapBusinessOne">
                <div className="grid-item-content">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/sap_bussi_one.png"
                      width={300}
                      alt="techpac"
                    />
                  </div>
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">
                      SAP Business One (B1)
                    </span>
                    <span className="sap-grid-description">
                      Gain greater control over your business or subsidiary with
                      SAP Business One. Streamline key processes, gain greater
                      insight into your business, and make decisions based on
                      real-time information – so you can drive profitable
                      growth..
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href="https://www.sap.com/products/business-one.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-row d-flex">
              <div className="grid-item light">
                <div
                  className="grid-item-content"
                  id="sapCustomerExperienceManagementSolution"
                >
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">
                      SAP Customer Experience Management Solution
                    </span>
                    <span className="sap-grid-description">
                      Understand your customers and engage them with
                      hyper-personalized experiences. Give them the freedom to
                      explore and interact – and choose you.
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href="https://www.sap.com/products/crm.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="grid-item dark" id="sapSuccessFactors">
                <div className="grid-item-content">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/Sap_su_fa.png"
                      width={300}
                      alt="techpac"
                    />
                  </div>
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">
                      SAP SuccessFactors (HCM)
                    </span>
                    <span className="sap-grid-description">
                      Put employees at the heart of everything you do with SAP
                      SuccessFactors software. Our cloud HCM solutions are
                      completely designed around employee experiences – what
                      they need, how they work, and what motivates them.
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href=" https://www.sap.com/products/human-resources-hcm.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-row d-flex">
              <div className="grid-item dark" id="sapAnalyticsCloud">
                <div className="grid-item-content">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/sap_an_cloud.png"
                      width={300}
                      alt="techpac"
                    />
                  </div>
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">SAP Analytics Cloud</span>
                    <span className="sap-grid-description">
                      The SAP Analytics Cloud solution combines BI, augmented
                      and predictive analytics, and planning capabilities into
                      one cloud environment. As the analytics layer of SAP’s
                      Business Technology Platform, it supports advanced
                      analytics enterprise-wide.
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href="https://www.sap.com/products/cloud-analytics.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="grid-item light"
                id="sapIntelligentSpendManagement"
              >
                <div className="grid-item-content">
                  <div className="image">{/* No Image  */}</div>
                  <div className="sap-grid-details">
                    <span className="sap-grid-title">
                      SAP Intelligent Spend Management
                    </span>
                    <span className="sap-grid-description">
                      It’s a strategy that shifts from traditional, disconnected
                      ways of managing spend to an integrated experience enabled
                      by intelligent technologies. Across channels and
                      categories, Intelligent Spend Management delivers a
                      single, unified view of your spend.
                    </span>
                  </div>
                  <div className="sap-grid-footer d-flex align-items-center">
                    <a
                      href="https://www.sap.com/cmp/dg/intelligent-spend-management/index.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="learn-more sap-learn-more">
                        LEARN MORE
                        <div className="icon">
                          <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                        </div>
                      </div>
                    </a>
                    <Link to="/contact">
                      <button>Connect Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="sap-client">
            <div className="">
              <div className="sap-clients-slider container">
                Curated Clientele
              </div>
              <div className="d-flex align-items-center">
                <div className="swiper-button-prev1"></div>
                <div className="client-slider-item">
                  <ClientsSlider slides={clients_slider} />
                </div>
                <div className="swiper-button-next1"></div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <BackgroundImage {...images.SuccsessStories} className="mid-screen">
            <div className="mid-screen-content container">
              <span className="mid-screen-title">Success Stories</span>
              <span className="mid-screen-description">
                Tech Pacific offers a wide range of SAP solutions for Digital
                Transformation, targeting multiple facets of a business. An
                offering tailored through bespoke service, it integrates digital
                technology into all areas of a business, resulting in
                fundamental operational changes.{" "}
              </span>
              <Link to="/success-stories">
                <div className="learn-more">
                  LEARN MORE
                  <div className="icon">
                    <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                  </div>
                </div>
              </Link>
            </div>
          </BackgroundImage>
        </section>
        <section>
          <div className="get-in-touch blue d-flex align-items-center justify-content-center">
            <div className="get-in-touch-content text-center container">
              <p>Get In Touch With Our Experts</p>
              <Link to="/contact">
                <button
                  className="btn btn-primary blue-contact-footer"
                  type="button"
                >
                  Let's Get to Work
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SAP {
    sapBg: file(relativePath: { eq: "sap/sapBg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Asset3: file(relativePath: { eq: "sap/Asset3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Sap_Products: file(relativePath: { eq: "home/Sap_Products.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Access: file(relativePath: { eq: "sap/clients/Access.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    SuccsessStories: file(
      relativePath: { eq: "products/digital-infrastructure/SuccessStories.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    y2014: file(relativePath: { eq: "sap/accolades/y2014.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    y2015: file(relativePath: { eq: "sap/accolades/y2015.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    y2016: file(relativePath: { eq: "sap/accolades/y2016.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    y2018: file(relativePath: { eq: "sap/accolades/y2018.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    y2020: file(relativePath: { eq: "sap/accolades/y2020.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    y2021: file(relativePath: { eq: "sap/accolades/y2021.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    y2022: file(relativePath: { eq: "sap/accolades/y2022.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    cwml: file(relativePath: { eq: "sap/accolades/cwml.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dimo: file(relativePath: { eq: "sap/accolades/dimo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ebc: file(relativePath: { eq: "sap/accolades/ebc.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hemas: file(relativePath: { eq: "sap/accolades/hemas.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lanka_ssl: file(relativePath: { eq: "sap/accolades/lanka_ssl.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    rockland: file(relativePath: { eq: "sap/accolades/rockland.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    wkv: file(relativePath: { eq: "sap/accolades/wkv.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sapacc: file(relativePath: { eq: "sap/accolades/sapacc.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    acorn: file(relativePath: { eq: "sap/clients/acorn.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    advantis: file(relativePath: { eq: "sap/clients/advantis.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    bondville: file(relativePath: { eq: "sap/clients/bondville.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    court: file(relativePath: { eq: "sap/clients/court.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    cwm: file(relativePath: { eq: "sap/clients/cwm.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    dimoc: file(relativePath: { eq: "sap/clients/dimo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ebcc: file(relativePath: { eq: "sap/clients/ebc.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ewm: file(relativePath: { eq: "sap/clients/tech_pacific.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    als: file(relativePath: { eq: "sap/clients/als.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    adani: file(relativePath: { eq: "sap/clients/adani.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ferentino: file(relativePath: { eq: "sap/clients/ferentino.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hemasc: file(relativePath: { eq: "sap/clients/hemas.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    iwmi: file(relativePath: { eq: "sap/clients/iwmi.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    jkh: file(relativePath: { eq: "sap/clients/jkh.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lanwa: file(relativePath: { eq: "sap/clients/lanwa.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    laugfs: file(relativePath: { eq: "sap/clients/laugfs.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lion: file(relativePath: { eq: "sap/clients/lion.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    loadstar: file(relativePath: { eq: "sap/clients/loadstar.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    melwa: file(relativePath: { eq: "sap/clients/cwm.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    printcare: file(relativePath: { eq: "sap/clients/printcare.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    rocklandc: file(relativePath: { eq: "sap/clients/rockland.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ssl: file(relativePath: { eq: "sap/clients/ssl.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    teejay: file(relativePath: { eq: "sap/clients/teejay.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tfg: file(relativePath: { eq: "sap/clients/tfg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    um: file(relativePath: { eq: "sap/clients/um.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    walkers: file(relativePath: { eq: "sap/clients/walkers.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    wkvc: file(relativePath: { eq: "sap/clients/wkv.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    atg: file(relativePath: { eq: "sap/clients/atg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    rocklandnew: file(relativePath: { eq: "sap/clients/rocklandnew.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    mangala: file(relativePath: { eq: "testimonials/mangala.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lalith: file(relativePath: { eq: "testimonials/lalith.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gerard: file(relativePath: { eq: "testimonials/gerard.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    vertiv: file(
      relativePath: { eq: "products/digital-infrastructure/LOGO.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    sap4hana: file(relativePath: { eq: "home/slider-logos/Sap4hana.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    sap_bBD: file(relativePath: { eq: "home/slider-logos/sap_bBD.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sapbussiness: file(relativePath: { eq: "home/slider-logos/sap_bBD.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default SAP
